@import "./bootstrap.scss";
@import "./components.scss";
@import "./bootstrap-extended.scss";
@import "./colors.scss";
@import "./iconfont.scss";
@import "./custom-rtl.scss";

.planner{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2em 1em;

  .planner-item{
  }
}

.modal-deliver-center{
  & .modal-dialog{
    width: 100%;
    max-width: 800px;
  }
}

.ag-root-wrapper-body{
  @media screen and (max-width: 768px) {
    font-size: 10px !important;
  }
}

.filter-pharmacie-visible{
  display: none;
  @media screen and (max-width: 992px) {
    display: block;
  }
}

.ag-theme-material .ag-cell{
  @media screen and (max-width: 992px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.ag-theme-material .ag-header-cell, .ag-theme-material .ag-header-group-cell{
  @media screen and (max-width: 992px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
